button {
  display: none;
}
a {
  color: white;
  text-decoration: none;
}
li {
  text-decoration: none;
}
.one{
  color:a;
}
.icon a {
  color:white;
}
.wheel {
  color: white;
  font-size: 4rem;
  text-align: center;
  position: relative;
  top: 18rem;
}
.changings {
  background-color: #fdb04b;
  box-shadow: 0rem 0.2rem 1rem 0.2rem #888484;
}
.dropdown a{
  color: rgba(15, 58, 4, 0.959);
  transition: color 1s;
}
.mainnav {
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  display: grid;
  grid-template-columns: 2rem 1fr 1fr 6fr 2rem;
}
.logo {
  display: grid;
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
  height: 5rem;
}
.logo img {
  height: 3rem;
}
.menu-link {
  grid-column: 4/5;
}
.icondrop {
  top: 4px;
  position: relative;
  left: 3px;
}
.menu-link ul {
  height: 5rem;
  display: flex;
  justify-content: space-around;
}
.icon {
  display: none;
}
.menu-link ul li {
  padding: 1.4rem 1rem;
  font-size: 1rem;
  list-style: none;
}
/* dropdown */
.dropclass:hover {
  transition: display 1s;
}
.dropclass .dropdown {
  position: absolute;
  display: none;
  top: 53px;
  border: 0.01px solid white;
  border-radius: 13%;
  height: fit-content;
  background-color: white;
  box-shadow: 0.2rem 0.1rem 0.3rem #eadbdb;
}
.dropclass:hover .dropdown {
  display: block;
}
.dropdown ul {
  display: block;
  height: fit-content;
}
.dropdown ul li {
  padding: 0.4em;
  border-bottom: 2px solid #e1dddd;
}
@media (max-width: 1080px) {
  .mainnav {
    height: 8rem;
    grid-template-columns: 2rem 1fr 1fr 6fr 2rem;
  }

  .logo,
  .menu-link ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .mainnav {
    position: fixed;
    background-color: #fdb04b;
    height: 6rem;
    grid-template-columns: 1rem 1fr 2fr 1fr 1rem;
    box-shadow: 0rem 0.2rem 1rem 0.2rem #888484;
  }
  .icondrop {
    display: none;
  }
  .logo,
  .menu-link {
    height: 5rem;
  }
  .menu-link ul {
    display: none;
  }
  .menu-link {
    position: relative;
  }
  .icon {
    display: block;
    font-size: 2.5rem;
    position: absolute;
    top: 10px;
    right: 2px;
  }
  .mobile-menu-link {
    color: black;
    background-color: #fdb04b;
    height: fit-content;
    display: grid;
    grid-column: 1/6;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .mobile-menu-link .icon {
    position: absolute;
    right: 10px;
    top: -70px;
  }
  .mobile-menu-link ul {
    height: fit-content;
    display: block;
  }
  .mobile-menu-link ul li {
    height: fit-content;
    display: block;
    padding-top: 1rem;
    padding-left: 1rem;
  }
  button {
    border: none;
    display: inline;
    background-color: #fdb04b;
    position: absolute;
    right: 2rem;
  }
  .dropclass:hover .dropdown {
    display: none;
  }
  .dropdown ul li {
    padding: 0.4em;
    border:none;
  }
}
.dropdown > ul > * {
  border-bottom: gray 1px solid;
}

.dropdown > ul > *:last-child {
  border-bottom: none;
}