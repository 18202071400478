.endf {
  font-weight: 650;
  text-align: center;
}

.overlay {
  background-color: #004428ab;
}

.endf h2 {
  margin: 0;
  padding: 2%;
  color: white;
  font-size: 1rem;
}

.links {
  display: flex;
  padding: 0 5%;
  height: 55px;
}

.iconf {
  margin: 10px;
  width: 50px;
  height: auto;
}