.date-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  color: rgb(107, 107, 107);
}

.head-box {
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
}

.head {
  text-decoration: underline solid;
  font-size: 3rem;
}

.tags-holder {
  padding-top: 2rem;
  color: rgb(107, 107, 107);
  display: flex;
  justify-content: space-between;
}

.icons {
  display: flex;
  flex-direction: row;
}

.shareicon {
  height: 2rem;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
}

.para {
  padding-bottom: 1rem;
}

.blogitems {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.blogitem {
  border-bottom: 2px rgba(184, 184, 184, 0.7) solid;
  padding: 1rem;
  box-shadow: 0px 0px 10px #33313859;
  border-left: 3px solid #ff2f009c;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.item-author {
  color: rgb(107, 107, 107);
}

.item-date {
  color: rgb(107, 107, 107);
  padding-left: 1rem;
}

.item-title {
  font-size: 2.5rem;
}

.subhead {
  padding-bottom: 10px;
}

.item-link {
  color: black;
}

.heading {
  text-align: center;
  height: fit-content;
  font-size: 187%;
  box-shadow: inset 13px -18px 0 2000px rgba(0 0 0 / 44%);
  background-size: cover;
  color: orange;
}

.content {
  background-color: white;
  color: black;
  line-height: 1.8rem;
  font-size: 1.1rem;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

blockquote {
  margin: 0 0 1rem;
  padding: 1rem;
  background-color: #8080803b;
  border-radius: 15px;
}

.content a {
  color: black;
}

.copy {
  cursor: pointer;
}

@media (max-width: 998px) {
  .blogitem {
    border-bottom: 2px rgba(184, 184, 184, 0.7) solid;
    padding: 1rem;
    box-shadow: 0px 0px 0px #33313859;
    border-left: 0px solid #ff2f009c;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .content {
    padding: 3rem 4%;
  }

  .contents {
    padding: 3rem 2% !important;
  }

  .tags-holder {
    flex-direction: column;
  }

  .icons {
    padding: 1rem 0;
  }
}
