.formBody {
    display: flex;
    flex-direction: column;
  }
  
  .formItem {
      background-color: white;
      margin: 0.5rem;
      padding: 0.8rem;
      border-bottom: red 2px solid;
      border-left: white 2px solid;
      border-right: white 2px solid;
      border-top: white 2px solid;
  }
  .formclass{
      background-color: white;
      color: black;
      /* width: 25%; */
      padding: 1.2rem;
      border-radius: 1rem;
  }
  .formItem:hover {
      transition-duration: 200ms;
      border: 2px black dotted;
      background: #02e2fc3d;
  }
  
  .formSubmit {
      margin: .5rem;
      padding: .8rem;
      border-radius: 2rem;
      background-color: #007a3f;
      color: white;
      border: #007a3f00 3px solid;
      transition-duration: 500ms;
  }
  
  .formSubmit:hover {
      cursor: pointer;
      color: black;
      background-color: white;
      transition-duration: 500ms;
      transition-timing-function: ease-in-out;
      border: #007a3f 3px solid;
  }