* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footerimage {
  background-image: url(../images/homepage/footer.png);
  box-shadow: inset 13px -18px 0 2000px rgba(0 0 0 / 44%);
  text-align: center;
  padding: 7% 7% 50px;
  background-size: cover;
}
.footerimage h1 {
  color: rgb(255, 217, 0);
  border: 4px solid rgb(255, 136, 0);
  border-left: 0;
  border-right: 0;
  padding: 7%;
  font-size: 1.5rem;
}
/* section two */
.two {
  text-align: center;
  padding: 1rem 9%;
  font-size: 1.3rem;
}
.two .above {
  padding: 4rem 14rem 1.5rem 14rem;
}
.above h1 {
  font-size: 2rem;
  font-weight: 700;
}
.above p {
  padding-top: 1rem;
}

.below {
  justify-content: center;
}

.two .below {
  display: flex;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}
.two .below .box img {
  height: 5rem;
}
.two .below .box h3 {
  padding-top: 1.5rem;
  font-size: 1rem;
  font-weight: 650;
}
.two .below .box p {
  padding: 1rem;
}
.blogs {
  margin: 1rem auto;
}
.blogs h2 {
  text-align: center;
  position: relative;
  top: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
}
.blogslist {
  display: flex;
  margin-top: 5rem;
  padding: 0rem 2rem;
  justify-content: space-evenly;
}
.blogbox {
  margin: 0.1rem 2rem;
}
.blogbox h3 {
  font-size: 1.3rem;
  font-weight: revert;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.blogbox .category {
  margin-bottom: 0.5rem;
  font-weight: 500;
}
@media (max-width: 998px) {
  .two {
    text-align: center;
  }
  .two .above {
    padding: 3rem 1rem 1rem;
    font-size: 1.2rem;
  }
  .above h1 {
    font-size: 1.5rem;
  }
  .above p {
    padding-top: 2rem;
  }
  .two .below {
    display: block;
    padding: 2rem 1rem;
  }
  .three .blogs {
    display: block;
    padding: 2rem 1rem;
  }
  .blogslist {
    display: block;
  }
  .blogbox {
    margin: 2.3rem auto;
  }
  .footerimage h1 {
    font-size: 2rem;
  }
}

.footertext {
  color: white;
}

.four {
  background-image: url("../images/misc/trees.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.fcontainer {
  padding: 4% 4%;
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.fheading {
  color: white;
}

hr {
  border-top: 2px solid rgba(255, 255, 255, 0.5)
}


.touch {
  width: 25%;
  padding: 0 2%;
}

.touch li {
  color: white;
}
.four h1 {
  color: white;
  line-height: 7rem;
  text-transform: capitalize;
}
.four ul {
  line-height: 3rem;
  padding-left: 15px;
}

.ani {
  position: relative;
}
.whole {
  box-shadow: inset 13px -18px 0 2000px rgba(0 0 0 / 44%);
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1260px) {
  .fcontainer {
    flex-direction: column;
  }

  .touch {
    width: auto;
  }
}

.heading {
  padding-top: 10%;
  padding-bottom: 8%;
}
.content {
  padding: 3rem 7%
}
.changedisplay {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 1%;
}
.layerimage {
  background-image: url(../images/misc/manure-1.png);
  box-shadow: inset 13px -18px 0 2000px rgba(0, 0, 0, 0.75);
  padding: 1.2% 2%;
  width: 100%;
  color:white;
  height: 32rem;
  border-radius: 5px;
  /* box-shadow: 0px 0px 15px yellow; */
}
.widthclass {
  width: 50%;
}

.iconstyle{
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  color: #006dff;
  transition: 0.5s;
}
.iconstyle:hover{
  background-color: #006dff;
  color:white;
}

.social-embed {
  display: flex;
  padding: 1rem;
  justify-content: space-evenly;
}

.embed {
  width: 33%
}

.form {
  width: 100%;
  height: 15rem;
}

@media (max-width: 998px) {
  .content {
    padding: 2rem 8%;
  }
  .heading {
    padding-top: 34%;
    padding-bottom: 12%;
  }
  .changedisplay {
    display: block;
  }
  .widthclass {
    width: 85%;
  }
  .layerimage {
    height:35rem;
    padding: 2%;
    width: 85%;
    margin: auto;
  }
  .social-embed {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .embed {
    width: 100%;
    padding: .5rem 2rem;
  }

} 

.imagedesign{  
  border-radius: 5%;
  transition: padding 1.1s;
  border:black solid 2px;
}
.imagedesign:hover{
  border: 3px solid yellowgreen;
  padding: 0.4rem;
  border-radius: 5.5%;
}

.partnerImgs {
  display: flex;
  justify-content: center;
}

@media (max-width: 998px) {
  .partnerImgs {
    flex-direction: column;
  }

  .partnerImgs > * {
    padding: 2rem 0;
  }
   
  .partnerImgs > div > img {
    width: 100%;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.h-40 {
  height: 40rem;
}

.story-title {
  padding: 0 5%;
}

.story-para {
  padding: 0 5%;
  color: grey;
}

@media (max-width: 998px) {
  .story-title {
    padding: 0 2.5%;
  }
  
  .story-para {
    padding: 0 2.5%;
    color: grey;
  }
}

.achi {
  display: flex;
  flex-direction: column;
}

.atitle {
  font-size: 2rem;
}

.achimg {
  width: 70%;
}
.imgcenter {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
}
.achitem {
  padding-top: 10px;
}

@media (max-width: 998px) {
  .atitle {
    font-size: 1.5rem;
  }
}